import stepOne from './stepOne';
import stepTwo from './stepTwo';
import stepThree from './stepThree';
import stepFour from './stepFour';
import stepFive from './stepFive';
import stepSix from './stepSix';
import stepSeven from './stepSeven';
import common from './common';
import CapitalPayment from './order/order'

export default {
  ...stepSeven,
  ...stepSix,
  ...stepFive,
  ...stepFour,
  ...stepThree,
  ...stepTwo,
  ...stepOne,
  ...common,
  ...CapitalPayment
};
