import React, { FC, useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import { useLazyQuery } from '@apollo/react-hooks';
import { CHECK_CONNECTION } from './graphql/account/query';
import { CheckConnection } from './graphql/account/types/CheckConnection';
import NoConnection from './components/CheckConnection/NoConnection';

const progress = () => <div>Loading ...</div>;

const load = (loader: any, loading?: any) =>
  Loadable({
    loader: () => loader,
    loading: loading === null ? () => null : loading || progress,
  });

const HipayAccept = load(import('./components/Hipay/Accept'));
const HipayPending = load(import('./components/Hipay/Pending'));
const HipayCancel = load(import('./components/Hipay/Cancel'));
const HipayDecline = load(import('./components/Hipay/Decline'));
const HipayException = load(import('./components/Hipay/Exception'));
const Layout = load(import('./components/Common/Layout/Layout'));
const Liveness = load(import('./components/Liveness/Liveness'));

const Routes: FC = () => {
  const CONNEXION_MAX = 5;
  const [countConn, setCountConn] = useState(0);
  const [getCon, { error }] = useLazyQuery<CheckConnection>(CHECK_CONNECTION, {
    onError: (error) => {
      setCountConn(countConn + 1);
    },
  });
  useEffect(() => {
    if (countConn == 0) {
      getCon();
    } else if (countConn < CONNEXION_MAX) {
      setTimeout(function () {
        getCon();
      }, 2000);
    }
  }, [countConn]);
  if (error && countConn === CONNEXION_MAX) {
    const { networkError } = error;
    if (networkError && networkError.message) {
      return <NoConnection />;
    }
  }
  return (
    <Switch>
      <Route exact={true} path="/hipay/accept" component={HipayAccept} />
      <Route exact={true} path="/hipay/cancel" component={HipayCancel} />
      <Route exact={true} path="/hipay/pending" component={HipayPending} />
      <Route exact={true} path="/hipay/decline" component={HipayDecline} />
      <Route exact={true} path="/hipay/exception" component={HipayException} />
      <Route exact={true} path="/liveness" component={Liveness} />
      <Route path="/" component={Layout} />
    </Switch>
  );
};

export default Routes;
