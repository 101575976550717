import React, { FC, Fragment, useEffect } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import Routes from './Route';
import GlobalSnackBar from './components/Common/GlobalSnackBar/GlobalSnackBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import ReactGA from 'react-ga4';
import { ID_GOOGLE_ANALYTICS } from './config';

ReactGA.initialize(`${ID_GOOGLE_ANALYTICS}`);

const App: FC = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: 'Home page souscription',
    });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalSnackBar />
      <BrowserRouter>
        <Fragment>
          <Routes />
        </Fragment>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
