export const API_URL = process.env.REACT_APP_API_URL;
export const API_SOUSCRIPTION = process.env.REACT_APP_API_SOUSCRIPTION;
export const DEFAULT_NAF_CODE =
  process.env.REACT_APP_DEFAULT_NAF_CODE || '4791A';
export const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL;
export const HIPAY_USERNAME = process.env.REACT_APP_HIPAY_USERNAME;
export const HIPAY_PASSWORD = process.env.REACT_APP_HIPAY_PASSWORD;
export const HIPAY_ENVIRONMENT = process.env.REACT_APP_HIPAY_ENVIRONMENT;
export const HIPAY_LANG = process.env.REACT_APP_HIPAY_LANG;
export const DEPOSIT_CAPITAL_AMOUNT =
  process.env.REACT_APP_DEPOSIT_CAPITAL_AMOUNT;
export const PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL;
export const PAYMENT_LINK = process.env.REACT_APP_PAYMENT_LINK;
export const CARD_LINK = process.env.REACT_APP_CARD_LINK;
export const SERVICE_LINK = process.env.REACT_APP_SERVICE_LINK;
export const ID_GOOGLE_ANALYTICS =
  process.env.ID_GOOGLE_ANALYTICS || 'G-ZPHTVYPGQK';
