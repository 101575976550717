import gql from 'graphql-tag';

const ACCOUNT_RESULT_FRAGEMENT = gql`
  fragment AccountResult on Success {
    action
    success
    message
  }
`;

const CREATE_ACCOUNT_SUCCESS_FRAGMENT = gql`
  fragment createAccountSuccessInfos on CreateAccountSuccess {
    action
    success
    message
    accessToken
  }
`;

const LOGIN_FRAGMENT = gql`
  fragment loginInfo on LoginSuccess {
    action
    success
    message
    accessToken
  }
`;

const OFFER_FRAGMENT = gql`
  fragment OfferInfo on Offer {
    id
    name
  }
`;

const OFFERS_FRAGMENT = gql`
  fragment OffersInfo on Offer {
    id
    name
    virtualCard
    physicalCard
    cardTransactions
    payins
    payouts
    cheque
    withdrawal
    smartAccess
    servicePlus
    liveCard
    smartDashboard
    categorisationAuto
    price
    limitPaymentMonth
    limitAtmWeek
  }
`;

const ACCOUNT_OFFER_FRAGMENT = gql`
  fragment AccountOfferInfo on Account {
    existingCompany
    offer {
      ...OfferInfo
    }
  }
  ${OFFER_FRAGMENT}
`;

const ACCOUNT_FRAGMENT = gql`
  fragment AccountInfo on Account {
    id
    email
  }
`;

const SIGNUP_PROGRESSION_FRAGMENT = gql`
  fragment AccountProgression on Account {
    signupProgression
  }
`;

export {
  ACCOUNT_RESULT_FRAGEMENT,
  OFFER_FRAGMENT,
  ACCOUNT_FRAGMENT,
  CREATE_ACCOUNT_SUCCESS_FRAGMENT,
  SIGNUP_PROGRESSION_FRAGMENT,
  LOGIN_FRAGMENT,
  ACCOUNT_OFFER_FRAGMENT,
  OFFERS_FRAGMENT,
};
