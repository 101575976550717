import { API_URL } from './config';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import resolvers from './apollo/resolvers';
import typeDefs from './apollo/typeDefs';
import { DocumentNode } from 'graphql';
import { ACCESS_TOKEN_KEY } from './services/LocalStorage';
import { GraphQLClient } from 'graphql-request';

export const createClient = (token: string | null) => {
  const httpLink = createHttpLink({
    uri: `${API_URL}`,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    typeDefs,
    resolvers,
  });
};

type IFetcherWeb = <TVariables, TResponse>(
  query: DocumentNode,
  variables: TVariables,
  endpoint: string | undefined,
) => Promise<TResponse>;

export const fetcherWEB: IFetcherWeb = async (query, variables, endpoint) => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  // ... or create a GraphQL client instance to send requests
  const client = new GraphQLClient(endpoint as string, {
    headers: { authorization: token ? `Bearer ${token}` : '' },
  });
  return client.request(query, variables as any);
};
